import $ from "jquery";
$(document).ready(function () {
  $("#topBtn").hide(); //ボタンを非表示にする
  $(window).on("scroll", function () {
    if ($(this).scrollTop() > 500) {
      //ページの上から500pxスクロールした時
      $("#topBtn").fadeIn("fast"); //ボタンがフェードインする
    } else {
      $("#topBtn").fadeOut("fast"); //ボタンがフェードアウトする
    }
    const scrollHeight = $(document).height(); //ドキュメントの高さ
    const scrollPosition = $(window).height() + $(window).scrollTop(); //現在地
    const footHeight = $(".footer").innerHeight(); //止めたい位置の高さ(今回はfooter)
    if (scrollHeight - scrollPosition <= footHeight) {
      //ドキュメントの高さと現在地の差がfooterの高さ以下の時
      $("#topBtn").css({
        position: "absolute", //pisitionをabsoluteに変更
        bottom: footHeight,
      });
    } else {
      //それ以外の場合は
      $("#topBtn").css({
        position: "fixed", //固定表示
        bottom: "110px",
      });
    }
  });

  //スムーススクロール設定
  $("#topBtn").click(function () {
    $("body,html").animate(
      {
        scrollTop: 0,
      },
      500
    ); //スムーススクロールの速度
    return false;
  });
});

// アンカーリンクのスムーズスクロール
$("a").on("click", function () {
  var headerHight = 0;
  var speed = 500;
  var href = $(this).attr("href");
  var target = href == "#" || href == "" ? "html" : href;
  if ($(target)[0] != undefined) {
    var position = $(target).offset().top - headerHight;
    $("body,html").animate({ scrollTop: position }, speed, "swing");
    return false;
  }
});

// header固定
function headerBorder() {
  return window.pageYOffset !== undefined
    ? window.pageYOffset
    : document.documentElement.scrollTop;
}
//
const hBorder = document.getElementById("headerFixed");
//ボタンの表示・非表示
window.onscroll = function () {
  headerBorder() > 100
    ? hBorder.classList.add("is-header")
    : hBorder.classList.remove("is-header");
};
